<template>
<div>
  <v-card flat>
    <v-card-text class="pa-1 title text-center">{{ $t("message.shifts.shift") }}</v-card-text>
    <v-row
      v-touch="{
        left: handleSwipeLeftEvent,
        right: handleSwipeRightEvent
      }"
      class="grey lighten-2 text-center ma-1 pa-0"
    >
      <v-col cols="4" class="pa-1">{{ previousMonth }}</v-col>
      <v-col cols="4" class="pa-1 font-weight-bold">{{ currentMonth }}</v-col>
      <v-col cols="4" class="pa-1">{{ nextMonth }}</v-col>
    </v-row>
    <v-row no-gutters v-if="showLoader">
      <v-col v-for="n in 5" :key="n" cols="12">
       <v-sheet class="my-2 px-2">
        <v-skeleton-loader class="mx-auto" type="list-item-avatar-three-line" height="75"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <v-card-text class="py-1 px-2" v-else-if="listOfShifts && listOfShifts.length > 0">
      <v-row class="px-1" v-for="(element, index) in listOfShifts" :key="index">
        <v-col class="pa-1 text-center" cols="2">
          <v-card flat class="pa-0" width="100">
            <p class="mb-0 font-weight-medium text-h6">{{element.date}}</p>
            <p class="mb-0 subtitle-2 font-weight-medium">{{element.day}}</p>
          </v-card>
        </v-col>
        <v-col class="pa-1" cols="10">
          <v-card class="mx-auto" outlined>
            <v-card-title class="pa-1 subtitle-1 font-weight-medium">
              {{element.time}}
              <v-spacer></v-spacer>
              <v-btn icon @click="showDetails(index, element.id)" ><v-icon>{{ element.active ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle class="pa-1">
              {{ element.text }} {{ element.project_name }}
            </v-card-subtitle>
            <v-expand-transition>
              <div v-show="element.active">
                <v-divider></v-divider>
                <v-card-text class="pa-1">
                  <div class="align_left"> {{ element.description }}</div>
                  <div class="align_left" style="font-size:14px" v-if="element.documents && element.documents.length > 0">{{ $t("message.shifts.documents") }}</div>
                  <div class="align_left" v-for="element in element.documents" :key="element.url">
                    <a :href="element.url" target="_blank">{{ element.name }}</a>
                  </div>
                  <div class="align_left" style="font-style: italic;" v-if="!element.description && !element.documents || !element.description && element.documents.length === 0"> {{ $t("message.shifts.not_found") }}</div>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-alert prominent text v-else type="info" class="mt-2 mx-1" >
      <v-row align="center">
        <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
      </v-row>
    </v-alert>
  </v-card>
</div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import VueCookie from 'vue-cookie'

const hostAppApi = axios.create({
  headers: {
    common: {
      Authorization: `Bearer ${VueCookie.get(process.env.VUE_APP_HOST_TOKEN)}`
    }
  }
})
export default {
  data () {
    return {
      currentMonth: '',
      previousMonth: '',
      nextMonth: '',
      header: '',
      start_date: '',
      end_date: '',
      showLoader: true,
      listOfShifts: [],
      hostRef: this.$cookie.get('AxentivPwa_host-ref')
    }
  },
  mounted () {
    if (!this.hostRef.includes(this.$store.state.common.host)) {
      this.hostRef += this.$store.state.common.host
    }
    moment.locale(this.$i18n.locale === 'no' ? 'nb' : 'en')
    this.currentMonth = moment().format('MMM YYYY')
    this.previousMonth = moment().subtract(1, 'months').format('MMM YYYY')
    this.nextMonth = moment().add(1, 'months').format('MMM YYYY')
    this.start_date = moment().startOf('month').format('YYYY-MM-DD HH:mm')
    this.end_date = moment().endOf('month').format('YYYY-MM-DD HH:mm')
    this.getCurrentMonthShifts()
  },
  methods: {
    handleSwipeLeftEvent () {
      this.showLoader = true
      this.previousMonth = moment(this.previousMonth, ['MMM YYYY']).add(1, 'months').format('MMM YYYY')
      this.currentMonth = moment(this.currentMonth, ['MMM YYYY']).add(1, 'months').format('MMM YYYY')
      this.nextMonth = moment(this.nextMonth, ['MMM YYYY']).add(1, 'months').format('MMM YYYY')
      this.start_date = moment(this.start_date, ['YYYY-MM-DD hh:mm']).add(1, 'months').format('YYYY-MM-DD HH:mm')
      this.end_date = moment(this.end_date, ['YYYY-MM-DD hh:mm']).add(1, 'months').format('YYYY-MM-DD HH:mm')
      this.getCurrentMonthShifts()
    },
    handleSwipeRightEvent () {
      this.showLoader = true
      this.previousMonth = moment(this.previousMonth, ['MMM YYYY']).subtract(1, 'months').format('MMM YYYY')
      this.currentMonth = moment(this.currentMonth, ['MMM YYYY']).subtract(1, 'months').format('MMM YYYY')
      this.nextMonth = moment(this.nextMonth, ['MMM YYYY']).subtract(1, 'months').format('MMM YYYY')
      this.start_date = moment(this.start_date, ['YYYY-MM-DD hh:mm']).subtract(1, 'months').format('YYYY-MM-DD HH:mm')
      this.end_date = moment(this.end_date, ['YYYY-MM-DD hh:mm']).subtract(1, 'months').format('YYYY-MM-DD HH:mm')
      this.getCurrentMonthShifts()
    },
    showDetails (index, id) {
      this.listOfShifts.find(x => x.id === id).active = !this.listOfShifts.find(x => x.id === id).active
      this.$set(this.listOfShifts, index, this.listOfShifts[index])
    },
    getCurrentMonthShifts () {
      const userId = this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0
      const model = { user_id: parseInt(userId), start_date: this.start_date, end_date: this.end_date }
      hostAppApi.post(`https://${this.hostRef}/api/get_my_events`, model)
        .then(response => {
          this.listOfShifts = response.data
          if (this.listOfShifts && this.listOfShifts.length) {
            for (let i = 0; i < this.listOfShifts.length; i++) {
              this.listOfShifts[i].date = moment(this.listOfShifts[i].start_date, ['YYYY-MM-DD']).format('DD')
              this.listOfShifts[i].day = moment(this.listOfShifts[i].start_date, ['YYYY-MM-DD']).format('ddd')
              const start = moment(this.listOfShifts[i].start_date)
              const end = moment(this.listOfShifts[i].end_date)
              // const differ = end.diff(start, 'days')
              if (end.diff(start, 'days') === 0) {
                const startTime = moment(this.listOfShifts[i].start_date, ['YYYY-MM-DD HH:mm:ss']).format('HH:mm')
                const endTime = moment(this.listOfShifts[i].end_date, ['YYYY-MM-DD HH:mm:ss']).format('HH:mm')
                this.listOfShifts[i].time = `${startTime} - ${endTime}`
              } else {
                this.listOfShifts[i].time = '-'
              }
              this.listOfShifts[i].expand = 'expand_more'
              this.listOfShifts[i].project_name = this.listOfShifts[i].project_name && this.listOfShifts[i].project_name.length > 0 ? '- ' + this.listOfShifts[i].project_name : ''
            }
          }
          this.showLoader = false
        }).catch(err => {
          this.showLoader = false
          if (err && err.message) {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(err.message), isException: true })
          }
        })
    }
  },
  watch: {
    '$i18n.locale' (val) {
      if (val) {
        const lang = val === 'no' ? 'nb' : 'en'
        const prevMonth = moment(this.previousMonth, 'MMM YYYY').toDate()
        const currMonth = moment(this.currentMonth, 'MMM YYYY').toDate()
        const nextMonth = moment(this.nextMonth, 'MMM YYYY').toDate()
        this.previousMonth = moment(prevMonth).locale(lang).format('MMM YYYY')
        this.currentMonth = moment(currMonth).locale(lang).format('MMM YYYY')
        this.nextMonth = moment(nextMonth).locale(lang).format('MMM YYYY')
        moment.locale(lang)
      }
    }
  }
}
</script>
